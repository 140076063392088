<template>
  <div class="container">

    <!-- 文件容量 -->
    <div class="content">
      <label class="title-l1">已用空间</label>
      <span class="progress">
        <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
      </span>
      <label>&nbsp;&nbsp;&nbsp;7GB / 10GB</label>
    </div>
    
    <div class="content">

      <!-- 文件类型 -->
      <div class="header-item">
        <el-radio-group v-model="searchType" @change="searchFloderOrFile()">
          <el-radio-button :label="0">文件夹</el-radio-button>
          <el-radio-button :label="1"  :disabled="isFileContainer && searchType == 0">图片</el-radio-button>
          <el-radio-button :label="21" :disabled="isFileContainer && searchType == 0">视频</el-radio-button>
          <el-radio-button :label="11" :disabled="isFileContainer && searchType == 0">Word</el-radio-button>
          <el-radio-button :label="12" :disabled="isFileContainer && searchType == 0">Excel</el-radio-button>
          <el-radio-button :label="13" :disabled="isFileContainer && searchType == 0">PPT</el-radio-button>
          <el-radio-button :label="14" :disabled="isFileContainer && searchType == 0">PDF</el-radio-button>
          <el-radio-button :label="10" :disabled="isFileContainer && searchType == 0">其他</el-radio-button>
        </el-radio-group>
      </div>

      <!-- 文件名称搜索框 -->
      <div class="header-item">
        <el-input
          placeholder="请输入文件名称"
          suffix-icon="el-icon-search"
          v-model="searchText">
        </el-input>
      </div>

      <!-- 搜索按钮 -->
      <div class="header-item">
        <el-button @click="searchFile()" type="primary" icon="el-icon-search">搜索</el-button>
      </div>

      <!-- 添加文件夹按钮 -->
      <div v-if="!isFileContainer && searchType == 0" class="header-item" style="float: right">
        <el-button @click="createFolder" plain icon="el-icon-folder-add">添加文件夹</el-button>
      </div>

      <!-- 上传文件按钮 -->
      <div v-if="isFileContainer && searchType == 0" class="header-item" style="float: right">
        <el-upload class="upload-demo"
          action="/api/upload/file"
          :show-file-list="false"
          :on-progress="uploadprogres"
          :on-success="uploadFile">
          <el-button type="primary" icon="el-icon-plus">上传文件</el-button>
        </el-upload>
      </div>

      <!-- 文件导航栏 -->
      <div class="breadcrumb">
        <span v-if="!isFileContainer || searchType != 0" class="nav">全部</span>
        <template v-if="isFileContainer && searchType == 0">
          <span @click="isFileContainer = false" class="nav link">返回上一级</span>
          <span class="nav padding-align">|</span>
          <span @click="isFileContainer = false" class="nav link">全部文件</span>
          <span class="nav zgcms padding-align" style="font-size: 12px">&#xe912;</span>
          <span class="nav">{{ currentFolder.name }}</span>
        </template>
        <span class="number">共{{isFileContainer ? currentFile.pageTotal : currentFolder.pageTotal}}个文件</span>
      </div>

      <!-- 文件夹列表及操作 -->
      <div v-show="!isFileContainer" class="folder-list">
        <el-table
          ref="multipleTable"
          :data="folderList"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
          @selection-change="selectFolderOrFile">
          <!-- 选择框 -->
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- 标题 -->
          <el-table-column label="名称">
            <template slot-scope="scope">
              <p @click="redirectFileList(scope.row)" class="table-title">
                <svg t="1593570175511" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10725"><path d="M918.673 883H104.327C82.578 883 65 867.368 65 848.027V276.973C65 257.632 82.578 242 104.327 242h814.346C940.422 242 958 257.632 958 276.973v571.054C958 867.28 940.323 883 918.673 883z" fill="#FFE9B4" p-id="10726"></path><path d="M512 411H65V210.37C65 188.597 82.598 171 104.371 171h305.92c17.4 0 32.71 11.334 37.681 28.036L512 411z" fill="#FFB02C" p-id="10727"></path><path d="M918.673 883H104.327C82.578 883 65 865.42 65 843.668V335.332C65 313.58 82.578 296 104.327 296h814.346C940.422 296 958 313.58 958 335.332v508.336C958 865.32 940.323 883 918.673 883z" fill="#FFCA28" p-id="10728"></path></svg>
                &nbsp;{{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <!-- 大小 -->
          <el-table-column label="大小" width="100">
            <template slot-scope="scope"><p>--</p></template>
          </el-table-column>
          <!-- 更新时间 -->
          <el-table-column label="更新时间" width="400">
            <template slot-scope="scope">
              <p>{{ scope.row.updatedAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
          <!-- 删除、重命名 -->
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button style="color: #ff0000"
                @click="updateFolderStatus(scope.row)" type="text" size="small">删除</el-button>
              <el-button 
                @click="updateFolder(scope.row)" type="text" size="small">重命名</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 20px; overflow: hidden">
          <el-button @click="batchUpdateFolderFile('del', 'folder')" :disabled="currentFolderFile.length < 1">批量删除</el-button>
          <div style="float: right">
            <el-pagination @current-change="getFolder" layout="total, prev, pager, next, jumper"
              :background="true"
              :current-page="currentFolder.pageIndex"
              :page-size="currentFolder.pageNumber"
              :total="currentFolder.pageTotal">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 文件列表及操作 -->
      <div v-if="isFileContainer" class="file-list">
        <el-table 
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :data="fileList"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
          @selection-change="selectFolderOrFile">
          <!-- 选择框 -->
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- 标题 -->
          <el-table-column label="名称">
            <template slot-scope="scope">
              <!-- 素材类型：1图片；10:其他；11-Word；12-Excel；13-PPT；14-PDF；21-视频 -->
              <p>
                <svg v-if="scope.row.type == 1" t="1593571023382" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2487" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#FF5562" p-id="2488"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#FFBBC0" p-id="2489"></path><path d="M758.4 705.6L658.4 550.4c-3.2-4.8-8-7.2-13.6-7.2s-10.4 3.2-13.6 7.2l-53.6 83.2-120-194.4c-3.2-4.8-8-7.2-13.6-7.2s-10.4 3.2-13.6 7.2L265.6 705.6c-3.2 4.8-3.2 11.2 0 16 3.2 5.6 8 8 13.6 8h465.6c5.6 0 11.2-3.2 14.4-8 2.4-5.6 2.4-12-0.8-16z" fill="#FFFFFF" p-id="2490"></path><path d="M662.4 412m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2491"></path></svg>
                <svg v-else-if="scope.row.type == 21" t="1593571185270" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2404" width="200" height="200"><path d="M80 34.4h864v960H80z" fill="#8095FF" p-id="2405"></path><path d="M176 112m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2406"></path><path d="M176 272m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2407"></path><path d="M176 432m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2408"></path><path d="M176 592m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2409"></path><path d="M176 752m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2410"></path><path d="M176 912m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2411"></path><path d="M864 112m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2412"></path><path d="M864 272m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2413"></path><path d="M864 432m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2414"></path><path d="M864 592m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2415"></path><path d="M864 752m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2416"></path><path d="M864 912m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z" fill="#FFFFFF" p-id="2417"></path><path d="M648 508L436 362.4c-4.8-3.2-11.2-4-16.8-0.8-5.6 3.2-8.8 8.8-8.8 14.4v290.4c0 5.6 3.2 11.2 8.8 14.4 5.6 3.2 12 2.4 16.8-0.8L648 533.6c4.8-2.4 7.2-8 7.2-12.8 0-4.8-3.2-9.6-7.2-12.8z" fill="#FFFFFF" p-id="2418"></path></svg>
                <svg v-else-if="scope.row.type == 11" t="1593571067623" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2627" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#6CCBFF" p-id="2628"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#C4EAFF" p-id="2629"></path><path d="M280 385.6h64.8l64.8 244h0.8l71.2-244H544l72 244 65.6-244H744L648 700h-64.8L512 458.4h-0.8l-72 240.8h-64.8L280 385.6z" fill="#FFFFFF" p-id="2630"></path></svg>
                <svg v-else-if="scope.row.type == 13" t="1593571104227" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2766" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#FF8976" p-id="2767"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#FFD0C8" p-id="2768"></path><path d="M385.6 385.6h176c70.4 0 92.8 47.2 92.8 97.6 0 48-28 96.8-92 96.8H445.6v120h-60V385.6z m60 145.6h96.8c34.4 0 52.8-10.4 52.8-47.2 0-38.4-24.8-48-48-48H445.6v95.2z" fill="#FFFFFF" p-id="2769"></path></svg>
                <svg v-else-if="scope.row.type == 12" t="1593571422440" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2554" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#5ACC9B" p-id="2555"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#BDEBD7" p-id="2556"></path><path d="M475.2 537.6l-108.8-152h75.2l71.2 108.8 74.4-108.8h72.8l-111.2 152 116.8 161.6h-76L511.2 584l-78.4 116h-74.4l116.8-162.4z" fill="#FFFFFF" p-id="2557"></path></svg>
                <svg v-else-if="scope.row.type == 14" t="1593571133929" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2905" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#FF5562" p-id="2906"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#FFBBC0" p-id="2907"></path><path d="M696 843.2c-50.4 0-95.2-86.4-119.2-142.4-40-16.8-84-32-126.4-42.4-37.6 24.8-100.8 61.6-149.6 61.6-30.4 0-52-15.2-60-41.6-6.4-21.6-0.8-36.8 5.6-44.8 12.8-17.6 39.2-26.4 79.2-26.4 32 0 72.8 5.6 118.4 16.8 29.6-20.8 59.2-44.8 85.6-70.4-12-56-24.8-146.4 8-188 16-20 40.8-26.4 70.4-17.6 32.8 9.6 44.8 29.6 48.8 44.8 13.6 54.4-48.8 128-91.2 171.2 9.6 37.6 21.6 76.8 36.8 112.8C663.2 704 735.2 744 743.2 788c3.2 15.2-1.6 29.6-13.6 41.6-10.4 8.8-21.6 13.6-33.6 13.6z m-74.4-121.6c30.4 61.6 59.2 90.4 74.4 90.4 2.4 0 5.6-0.8 10.4-4.8 5.6-5.6 5.6-9.6 4.8-12.8-3.2-16-28.8-42.4-89.6-72.8z m-296-82.4c-39.2 0-50.4 9.6-53.6 13.6-0.8 1.6-4 5.6-0.8 16.8 2.4 9.6 8.8 19.2 29.6 19.2 25.6 0 62.4-14.4 105.6-40-31.2-6.4-58.4-9.6-80.8-9.6z m158.4-4.8c25.6 7.2 52 16 76.8 25.6-8.8-23.2-16-47.2-22.4-70.4-17.6 15.2-36 30.4-54.4 44.8zM583.2 376c-8.8 0-15.2 3.2-20.8 9.6-16.8 20.8-18.4 73.6-5.6 140.8 48.8-52 75.2-100 68.8-125.6-0.8-4-4-15.2-26.4-21.6-6.4-2.4-11.2-3.2-16-3.2z" fill="#FFFFFF" p-id="2908"></path></svg>
                <svg v-else t="1593570955758" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2349" width="200" height="200"><path d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6 9.6 9.6 21.6 14.4 33.6 14.4h704c12 0 24.8-4.8 33.6-14.4 9.6-9.6 14.4-21.6 14.4-33.6V304L640 32H160z" fill="#E5E5E5" p-id="2350"></path><path d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z" fill="#CCCCCC" p-id="2351"></path></svg>
                &nbsp;
                <span v-if="scope.row.type == 1 || scope.row.type == 21"
                  class="table-title"
                  @click="preview(scope.row)">{{ scope.row.title }}</span>
                <span v-else>{{ scope.row.title }}</span>
              </p>
            </template>
          </el-table-column>
          <!-- 大小 -->
          <el-table-column label="大小" width="100">
            <template slot-scope="scope">
              <p>{{ scope.row.configs.size || '--' }}</p>
            </template>
          </el-table-column>
          <!-- 更新时间 -->
          <el-table-column label="更新时间" width="400">
            <template slot-scope="scope">
              <p>{{ scope.row.updatedAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
          <!-- 发布、下架、删除 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button style="color: #ff0000"
                @click="updateFileStatus(scope.row)" type="text" size="small">删除</el-button>
              <el-button 
                @click="updateFileTitle(scope.row)" type="text" size="small">重命名</el-button>
              <el-button 
                @click="updateFileFolder('openDialog', scope.row)" type="text" size="small">移动到</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px; overflow: hidden">
          <el-button @click="batchUpdateFolderFile('del', 'file')" :disabled="currentFolderFile.length < 1">批量删除</el-button>
          <div style="float: right">
            <el-pagination
              :background="true"
              @current-change="getFile"
              :current-page="currentFile.pageIndex"
              :page-size="currentFile.pageNumber"
              layout="total, prev, pager, next, jumper"
              :total="currentFile.pageTotal">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 上传进度条 -->
      <el-dialog center title="上传进度" width="600px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="isUploadProgres">
        <p style="color: #ff0000">提示：文件上传完毕前请不要关闭/刷新此页面</p><br>
        <el-progress :text-inside="true" :stroke-width="16" :percentage="uploadProgresNum"></el-progress>
      </el-dialog>

      <!-- 文件转移 -->
      <el-dialog title="移动到" width="600px"
        @open="getAllFolder"
        :visible.sync="moveFile.dialogVisible">
        <el-tree v-if="allFolderList.length > 0" class="tree"
          node-key="id"
          :indent="10"
          :expand-on-click-node="false"
          :default-expanded-keys="moveFile.key"
          :data="allFolderList">
          <span @click="updateFileFolder('selectFolder', data, node)" style="display: inline-block; width: 100%" slot-scope="{node, data}">
            <svg t="1593570175511" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10725"><path d="M918.673 883H104.327C82.578 883 65 867.368 65 848.027V276.973C65 257.632 82.578 242 104.327 242h814.346C940.422 242 958 257.632 958 276.973v571.054C958 867.28 940.323 883 918.673 883z" fill="#FFE9B4" p-id="10726"></path><path d="M512 411H65V210.37C65 188.597 82.598 171 104.371 171h305.92c17.4 0 32.71 11.334 37.681 28.036L512 411z" fill="#FFB02C" p-id="10727"></path><path d="M918.673 883H104.327C82.578 883 65 865.42 65 843.668V335.332C65 313.58 82.578 296 104.327 296h814.346C940.422 296 958 313.58 958 335.332v508.336C958 865.32 940.323 883 918.673 883z" fill="#FFCA28" p-id="10728"></path></svg>
            {{ node.label }}
          </span>
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="moveFile.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateFileFolder('update')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ResourceDialog from '@/websites/cms/js/dialog'
export default {
  data(){
    return {
      searchType: 0, // 搜索资源的type，0：文件夹，1：图片，8：文档，9：视频
      searchText: '', // 搜索框字段
      currentFolderFile: [], //当前选中的文件/文件夹
      folderList: [], // 分页文件夹列表
      allFolderList: [], // 所有文件夹列表
      currentFolder: { // 当前文件夹数据
        id: "", // 当前文件夹ID
        name: "", // 当前文件夹名称
        pageIndex: 1,
        pageNumber: 8,
        pageTotal: 0,
      },
      isFileContainer: false, // 是否显示指定文件夹的文件列表
      fileList: [], // 文件列表
      currentFile: { // 当前文件夹的文件列表
        pageIndex: 1,
        pageNumber: 8,
        pageTotal: 0
      },
      isUploadProgres: false, // 上传文件进度条
      uploadProgresNum: 0, // 上传文件进度条数值
      moveFile: {
        dialogVisible: false, // 移动文件弹窗开关
        key: ['0'], // 移动文件弹窗中默认展开的key
        currentFileTitle: "", // 当前选中的file的title 
        currentFileId: "", // 当前选中的file的ID
        currentFolderId: "", // 在移动文件弹窗中选中的folder的ID
      }
    }
  },
  created() {
    this.getFolder();
  },
  methods: {
    // 分页查询文件夹
    async getFolder(pageIndex){
      this.currentFolder.pageIndex = pageIndex;
      let params = {
        title: this.searchText,
        pageIndex: pageIndex || this.currentFolder.pageIndex,
        pageNumber: this.currentFolder.pageNumber
      }
      let res = await this.$ZGManager.getFolder(params);
      if(res.status == 200 && res.data){
        this.folderList = res.data.list;
        this.currentFolder.pageTotal = res.data.totalRows;
      }
      this.isFileContainer = false;
    },

    // 查询所有文件夹
    async getAllFolder(){
      this.allFolderList = [];
      let res = await this.$ZGManager.getAllFolder();
      if(res.status == 200 && res.data){
        let data = {
          id: "0",
          name: "全部文件夹",
          label: "全部文件夹",
          children: []
        }
        res.data.map((e) => {
          e.label = e.name;
          e.children = [];
          data.children.push(e);
        });
        this.allFolderList.push(data);
      }
    },
    
    // 新增文件夹
    async createFolder(){
      let dialog = await this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{2,15}$/,
        inputErrorMessage: '文件夹名称应在2-15个字'
      });
      if(dialog.action != 'confirm') return; 
      let res = await this.$ZGManager.createFolder(dialog.value);
      if(res.status == 200 && res.data){
        this.getFolder();
        this.$message.success("文件夹创建成功");
      }
    },

    // 修改文件夹状态(目前只有删除功能)
    async updateFolderStatus(item){
      let dialog = await this.$confirm(`是否要删除 ”${item.name}“`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.updateFolderStatus(item.id, -1);
      if(res.status == 200 && res.data){
        this.getFolder();
      }
    },

    // 修改文件夹名称
    async updateFolder(item){
      let dialog = await this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.name,
        inputPattern: /^.{2,15}$/,
        inputErrorMessage: '文件夹名称应在2-15个字'
      });
      if(dialog.action != 'confirm') return;
      let res = await this.$ZGManager.updateFolder(item.id, {name: dialog.value});
      if(res.status == 200 && res.data){
        this.getFolder();
        this.$message.success("修改成功");
      }
    },

    // 跳转到文件列表
    redirectFileList(item){
      this.currentFolder.id = item.id;
      this.currentFolder.name = item.name;
      this.getFile();
    },

    // 获取文件列表
    async getFile(pageIndex){
      this.currentFile.pageIndex = pageIndex;
      // 显示文件列表容器
      let params = {
        folderId: this.currentFolder.id || '',
        title: this.searchText,
        status: 1, // 0：禁用；1：启用
        type: this.searchType == 0 ? '' : this.searchType,
        pageIndex: this.currentFile.pageIndex,
        pageNumber: this.currentFile.pageNumber
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status == 200 && res.data){
        let data = res.data.list;
        data.map(e => e.configs = JSON.parse(e.configs));
        this.fileList = data;
        this.currentFile.pageTotal = res.data.totalRows;
      }
      this.isFileContainer = true;
    },

    // 上传文件
    async uploadFile(res, file){
      if(res.status != 0) return this.$message.error("服务异常，请稍后再试");
      let params = { title: file.name, folderId: this.currentFolder.id };
      let size;
      if(file.size < (1024 * 1024)){
        size = `${Math.ceil(file.size / 1024)} KB`;
      }else{
        size = `${(Math.ceil(file.size / 1024 / 1024 * 100)) / 100} MB`;
      }
      let configs = { url: res.data.url, size: size };
      // 素材类型：1图片；10:其他；11-Word；12-Excel；13-PPT；14-PDF；21-视频
      let image = /\.*(png|jpg|jpeg)$/;
      let video = /\.*(mp4)$/;
      let excel = /\.*(xls|xlsx|spreadsheetml.sheet)$/;
      let ppt   = /\.*(ppt|pptx|presentationml.presentation|application\/vnd.ms-powerpoint)$/;
      let word  = /\.*(doc|docx|wordprocessingml.document)$/;
      let pdf   = /\.*(pdf)$/;
      if(image.test(file.raw.type)){
        params.type = 1;
      }else if(video.test(file.raw.type)){
        params.type = 21;
      }else if(excel.test(file.raw.type)){
        params.type = 12;
      }else if(ppt.test(file.raw.type)){
        params.type = 13;
      }else if(word.test(file.raw.type)){
        params.type = 11;
      }else if(pdf.test(file.raw.type)){
        params.type = 14;
      }else{
        params.type = 10;
      }
      params.configs = JSON.stringify(configs);
      let result = await this.$ZGManager.addMaterial(params);
      if(result.status != 200) return;
      this.$message.success("上传成功");
      this.getFile();
    },

    // 修改文件状态
    async updateFileStatus(item){
      let dialog = await this.$confirm(`是否要删除 ”${item.title}“`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.updateMaterialStatus(item.id, -1);
      if(res.status == 200 && res.data){
        this.$message.success("删除成功");
        this.getFile();
      }
    },

    // 修改文件标题
    async updateFileTitle(item){
      let dialog = await this.$prompt('请输入文件名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.title,
        inputPattern: /^.{2,30}$/,
        inputErrorMessage: '文件名称应在2-30个字符内'
      });
      if(dialog.action != 'confirm') return;
      let res = await this.$ZGManager.updateMaterial(item.id, {title: dialog.value});
      if(res.status == 200 && res.data){
        this.$message.success("修改成功");
        this.getFile();
      }
    },

    // 搜索文件/文件夹
    searchFloderOrFile(){
      this.searchText = "";
      this.currentFolder.id = "";
      this.currentFolder.name = "";
      if(this.searchType == 0){
        this.currentFolder.pageIndex = 1;
        this.getFolder();
      }else{
        this.currentFile.pageIndex = 1;
        this.getFile();
      }
    },
    
    // 搜索文件
    searchFile(){
      this.currentFile.pageIndex = 1;
      this.getFile();
    },

    // 当前列表选中的文件/文件夹
    selectFolderOrFile(val){
      this.currentFolderFile = val;
    },

    // 批量处理文件/文件夹
    async batchUpdateFolderFile(action, type){ 
      switch(action){
        case "del":
          let dialog = await this.$confirm(`确定删除所选文件？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          });
          if(dialog != 'confirm') break;
          if(type == 'folder'){
            this.currentFolderFile.map(e => this.$ZGManager.updateFolderStatus(e.id, -1));
            let timer = setTimeout(() => {
              this.getFolder();
              this.currentFolderFile = [];
              clearTimeout(timer);
            }, 1000);
          }else if(type == 'file'){
            this.currentFolderFile.map(e => this.$ZGManager.updateMaterialStatus(e.id, -1));
            let timer = setTimeout(() => {
              this.getFile();
              this.currentFolderFile = [];
              clearTimeout(timer);
            }, 1000);
          }
          break;
        case "move":
          this.$message.warning("攻城狮努力开发中");
          break;
        default: break;
      }
    },

    // 将文件移动到指定文件夹
    async updateFileFolder(action, data){
      switch(action){
        case "openDialog":
          this.moveFile.currentFileId = data.id;
          this.moveFile.currentFileTitle = data.title;
          this.moveFile.dialogVisible = true;
          break;
        case "selectFolder":
          this.moveFile.currentFolderId = data.id;
          break;
        case "update":
          let params = {
            title: this.moveFile.currentFileTitle,
            folderId: this.moveFile.currentFolderId
          }
          let res = await this.$ZGManager.updateMaterial(this.moveFile.currentFileId, params);
          if(res.status == 200 && res.data){
            this.$message.success("操作成功");
            this.moveFile.dialogVisible = false;
            this.getFile();
          }
        default: break;
      }
    },

    // 上传进度条
    uploadprogres(e){
      if(e.percent >= 100){
        this.isUploadProgres = false;
      }else{
        this.uploadProgresNum = Math.ceil(e.percent);
        this.isUploadProgres = true;
      }
    },

    // 预览资源
    preview(item){
      ResourceDialog.show({
        type: 'previewResource',
        resource: item,
        resourceType: item.type == 1 ? 'image' : 'video',
        resourceTitle: item.title,
        resourceUrl: item.configs.url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .progress{
    display: inline-block;
    width: 200px;
    ::v-deep .el-progress-bar__outer{
      height: 16px!important;
    }
  }
  .title-l1{
    font-size: 14px;
    font-weight: bold;
    color: rgb(78, 78, 78);
    vertical-align: middle;
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.breadcrumb{
  padding: 15px 10px;
  background-color: rgb(246, 247, 248);
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
  overflow: hidden;
  ::v-deep .el-breadcrumb{
    width: 800px;
    display: inline-block;
  }
  .nav{
    color: #777;
  }
  .link{
    color: #578bcf;
    cursor: pointer;
  }
  .padding-align{
    padding: 0 10px;
  }
  .number{
    float: right;
    margin-right: 30px;
    color: #777;
  }
}
.table-title{
  color: #578bcf;
  cursor: pointer;
}
svg{
  vertical-align: sub;
  width: 1.5em;
  height: 1.5em;
}
</style>